<template>
  <div class="dynamic-container">
      <h2>THIS IS A DYNAMIC COMPONENT</h2>
      <component :is="view"/>
      </div>
</template>

<script>
import TestCard from './TestCard'
export default {
  components: {
    TestCard
  },
  setup () {
    const view = 'TestCard'
    return { view }
  }
}
</script>

<style>

</style>
