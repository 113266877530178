<template>
    <div class="butter-cms">
      <h2>Butter CMS demo</h2>
      <h3>Check console logs for <span style="color:red">Butter CMS</span> to view its data.</h3>
        This value is from the cms data - <b> {{hero.hero_title}} </b>
        </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import ButterCMS from 'buttercms'
export const Butter = ButterCMS('11fe9c81b0641316d575010280b9eab2c9bcb700')
export default {
  setup () {
    const hero = ref({})
    const getCMSData = () => {
      Butter.page.retrieve('*', 'magnaflow-autozone')
        .then(function (resp) {
          hero.value = resp.data.data.fields.hero[0]
        })
        .catch(function (resp) {
          console.log('%cERROR Butter Data:', 'color:blue', resp)
        })
    }
    onMounted(getCMSData)
    return { hero }
  }
}
</script>

<style>

</style>
