<template>
  <div class="transition-test-container">

      <el-button @click="switchCards">switchCards</el-button>
      {{showCard}}
      <transition @before-enter='beforeEnter' @enter='enter' @leave='leave' :css='false' mode="out-in">
        <el-card v-if="showCard" :body-style="{ padding: '0px' }">
       blank card
      </el-card>
      <div v-else>
          in the vue transition make sure you set mode 'out-in' so you get your transition events firing properly
          </div>
      </transition>
      </div>
</template>

<script>
import { ref } from 'vue'
import gsap from 'gsap'
export default {
  setup () {
    const showCard = ref(true)
    const switchCards = () => {
      showCard.value = !showCard.value
    }
    const beforeEnter = (el) => {
      gsap.set(el, { autoAlpha: 0 })
    }
    const enter = (el, done) => {
      gsap.to(el, { duration: 2, autoAlpha: 1, onComplete: done })
    }
    const leave = (el, done) => {
      gsap.to(el, { duration: 2, autoAlpha: 0, onComplete: done })
    }
    return { switchCards, showCard, beforeEnter, enter, leave }
  }
}
</script>

<style>
.transition-test-container{
    width:500px;
    margin:0 auto;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
</style>
